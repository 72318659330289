import React, { useState, useEffect, useCallback } from "react";
import Img from "gatsby-image";
import { StaticQuery, graphql, Link } from "gatsby";
import { dateOutputInXDays } from '../helpers/dates'
const SeeTheDifferenceSidebar = () => (
  <div
  className={`rounded-lg mb-6 shadow bg-white bg-brand-500`}
 
>
  <div className="px-8 py-6 text-center">
    <h2 className="text-white font-bold text-3xl md:text-4xl leading-10">
    Listing vs. Selling To Us
    </h2>
    <span className="text-white font-bold text-base leading-6 py-3 block">
    Which route is quicker?
Puts more cash in your pocket?
Has less hassle?
    </span>
    <Link
      to={"/compare"}
      className="button-active-bhvr my-2 inline-block font-bold tracking-tight text-lg lg:text-xl cursor-pointer transition duration-150 ease-in-out text-brand-600 focus:text-brand-700  focus:outline-none px-5 py-2  rounded-full  bg-white shadow font-display"
    >
      See The Difference
    </Link>
  </div>

  <div>
      
  </div>
</div>
);

export default SeeTheDifferenceSidebar;
