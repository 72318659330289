import React from "react";
import Layout from "../components/Layout";
import SEO from "../components/SEO";
import ContactForm from '../components/ContactForm'
import Img from "gatsby-image";
import HowItWorks from "../components/HowItWorks";
import AgentvsSellFastSactable from '../components/AgentvsSellFastSacTable'
import SeeTheDifferenceSidebar from '../components/SeeTheDifferenceSidebar'
import foreclosureWideBlue from '../img/foreclosure-wide-blue.png'


const AvoidingForeclosure = () => {

  return (
    <Layout headerTransparent={false}>
      <SEO
        title={'Avoiding Foreclosure On Your House | Sell Your Home For Cash Sacramento'}
        description={'So, are you in foreclosure and want to learn how to stop foreclosure now? We&#039;ve created free online guides to help homeowners just like you find out your'}
        keywords={''}
      />






      <section className="py-16 section-stripe-bg bg-gray-50 ">
        <div className="flex flex-wrap px-6 md:px-8 container mx-auto ">
          <div className="w-full md:w-2/3 md:pr-4 prose">

            <img src={foreclosureWideBlue} />
            <div className="flex items-wrap">

              <div className="w-full md:w-1/2">

                <h3><strong>“List My House For Sale”</strong></h3>
                <ul>
                  <li>Have 4-6 months? A listing may put more in your pocket</li>
                  <li>Save your credit from the wreckage of a foreclosure</li>
                  <li>See if your situation qualifies</li>
                </ul>
                <a href="/contact-us/" style={{ color: '#fff', textDecoration: 'none' }} className="mt-4 button-active-bhvr w-full cursor-pointer transition duration-150 ease-in-out bg-gradient-to-r from-accent-500 to-accent-700   focus:outline-none font-semibold px-4 py-3 lg:py-3 lg:px-5 rounded-full text-white font-display flex items-center justify-center text-white">Learn If Listing My House Is The Best Fit →</a>


              </div>
              <div className="w-full md:w-1/2">

                <h3><strong>“Get A Fair Cash Offer Today”</strong></h3>
                <ul>
                  <li>Let us know about your situation and your house</li>
                  <li>We’ll evaluate it quickly (usually within 48 hours)</li>
                  <li>You’ll receive a fair win-win all cash offer</li>
                </ul>
                <a href="/get-a-cash-offer-today/" style={{ color: '#fff', textDecoration: 'none' }} className="mt-4 button-active-bhvr w-full cursor-pointer transition duration-150 ease-in-out bg-gradient-to-r from-accent-500 to-accent-700   focus:outline-none font-semibold px-4 py-3 lg:py-3 lg:px-5 rounded-full text-white font-display flex items-center justify-center">Get A Fair All Cash Offer To Sell Quick →</a>


              </div>

            </div>

            <hr />
            <h3>Want To Learn How To Stop Foreclosure Now?</h3>
            <p>So, are you in foreclosure and want to learn how to <a href="/go/stop-foreclosure/">stop foreclosure now</a>? &nbsp;We’ve created free online guides to help homeowners just like you find out your options on getting out of the sticky situation you’re in right now.</p>
            <p>You aren’t the first person to go through a foreclosure… and won’t be the last. &nbsp;So don’t feel ashamed. It happens.</p>
            <p>The best thing you can do right now is to educate yourself on your options. &nbsp;For some people selling your home is the best option (we’ll make a fair all-cash offer on your house today, <a href="/sell-your-house/">just let us know about your situation here</a> &lt;&lt;), sometimes we’re able to help homeowners STOP FORECLOSURE completely, and sometimes there are other options. &nbsp;So, click one of the buttons above to get your <strong>free foreclosure guide</strong>.</p>


          </div>
          <div className="w-full md:w-1/3 md:pl-4">



            <div className="rounded-xl mb-2 shadow bg-white  mt-6 md:mt-0">
              <div className="px-8 pt-8">
                <h2 className="text-gray-800 font-bold text-2xl md:text-3xl leading-8">Get Your Fair Cash Offer: Start Below!</h2>
                <p class="text-gray-600 mb-2 leading-6 mt-1">We buy houses in any condition -- no commissions or fees and no obligation whatsoever. Start below by giving us a bit of information about your property or call 916-237-9457.</p>
              </div>


              <div className="p-8 pt-4"><ContactForm stacked={true} hideLabels={true} showPlaceholders={true} /></div>

            </div>
            <SeeTheDifferenceSidebar />
          </div>
        </div>

      </section>




    </Layout>
  );
};


export default AvoidingForeclosure;

